.Controls {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  margin: 16px;
  opacity: 0.25;
}

.Controls:hover {
  opacity: 1;
}

.Controls p.Details {
  padding: 0;
  margin: 0 0 0.4rem;
}

body, html, div#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

div.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  /*Required to make the accessibility div added by pixi not add scrollbars on WallpaperContainer*/
  position: absolute;
}

div.Loading {
  z-index: 1000;
  color: #EFEFEF;
  font-size: 2rem;
  flex-direction: column;
}

div.Loading p {
  padding: 2rem;
}

img.SourceImage {
  max-width: 100%;
}

div.WallpaperContainer {
  /* set line-height to avoid extra space after the wallpaper <canvas> */
  line-height: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ControlFormItem {
  width: 100%;
  margin-top: 16px;
}

.FullWidth {
  width: 100%;
}

.BottomRightSlideInArea {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 128px 32px 32px 128px;
}

canvas {
  box-shadow: 0 0 30px #808080;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 0.9em;
}

.FormControl {
  margin-bottom: 8px;
}